/* eslint-disable no-await-in-loop */
import { create, ApisauceInstance, ApiOkResponse } from 'apisauce'
import { debounce } from 'lodash'
import {
  Balances,
  DecodedItem,
  ItemIdentifier,
  Items,
  RfidAntennas,
  TmrBalance,
  TmrRfidAntenna,
  TmrTag,
  TmrWorkstation,
  DecodeRequest
} from 'stylewhere/api'
import { T, __ } from 'stylewhere/i18n'
import { AppStore, Sounds } from 'stylewhere/shared'
import { showToast, showToastError, sleep } from 'stylewhere/utils'

const WRITE_DEVICE_TYPE_READER = ['nordic-id-sampo-native', 'sensormatic', 'impinj-speedway-octane', 'zebra-fx-series']
const READERS_SUPPORTING_CUSTOM_COMMANDS = ['mediol-40']

interface UpdateConfig {
  remoteUrl: string
  remotePath: string
  username: string
  password: string
  connectionType: 'SFTP'
  backupPaths?: string[]
  cleanupPatterns?: string[]
  excludePatterns?: string[]
}

interface DeviceManagerStatus {
  status: string
  telemetry: string
  lastUpdate: string
}

class RfidReader {
  deviceManagerApi!: ApisauceInstance
  webSocket?: WebSocket[]
  sessionId = 'client1'
  wakeUpSocketTimeout?: NodeJS.Timeout
  workstation?: TmrWorkstation
  tags!: TmrTag[]
  decodeRequest?: DecodeRequest
  operationId?: string
  debounceDecodeTime?: number

  timer?: any
  timerMs?: number
  automaticStop?: boolean

  batchInterval: boolean
  batchIntervalTime: number
  batchIntervalTagCount: number
  batchIntervalTimer?: any
  batchIntervalTags: any[]

  isManualStop: boolean
  restartAntennas: boolean

  current_antenna = -1
  ws_configurations: string[] = []

  restart_onerror_counter = 0
  restart_onerror_max = 4

  customCommandsWs?: WebSocket = undefined

  ignoreReadingTagsWithPrefixes: string[] = []

  currentVersion: string = ''

  constructor() {
    this.timerMs = 5000
    this.automaticStop = true
    this.debounceDecodeTime = 300
    this.batchInterval = false
    this.batchIntervalTime = 1000
    this.batchIntervalTagCount = 200
    this.batchIntervalTags = []
    this.isManualStop = false
    this.restartAntennas = false
  }

  setAutomaticStop = (val: boolean) => {
    this.automaticStop = val
  }

  setAutomaticStopTime = (time: number) => {
    this.timerMs = time
  }

  setDebounceDecodeTime = (val: number) => {
    this.debounceDecodeTime = val
  }

  setBatchInterval = (val: boolean) => {
    this.batchInterval = val
  }

  setBatchIntervalTime = (val: number) => {
    this.batchIntervalTime = val
  }

  setBatchIntervalTagCount = (val: number) => {
    this.batchIntervalTagCount = val
  }

  setIgnoreReadingTagsWithPrefixes = (val: string) => {
    if (val !== '') {
      this.ignoreReadingTagsWithPrefixes = val.split(',')
    }
  }

  onTagReadCallback?: (tag: TmrTag) => void
  onStopCallback?: () => void
  onStartCallback?: () => void
  onDecodedItemCallback?: (items: { [epc: string]: DecodedItem }, tags: TmrTag[]) => void
  onPendingTagsChangeAntennaButton?: (pendingTags: number) => void
  connectionFailedCallback?: () => void
  onStartCallbackAntennaButton?: () => void
  onStopCallbackAntennaButton?: () => void
  decodeFunction?: (epcs: string[]) => Promise<any>

  startTimer = () => {
    if (this.automaticStop) {
      this.stopTimer()
      this.timer = setTimeout(() => {
        this.stop()
      }, this.timerMs)
    }
  }

  stopTimer = () => {
    if (!this.timer) return
    clearTimeout(this.timer)
    this.timer = undefined
  }

  async initialize() {
    try {
      console.info('RfidReader initialize')
      this.onDecodedItemCallback = undefined
      this.onTagReadCallback = undefined
      this.decodeFunction = undefined
      this.decodeRequest = undefined
      this.tags = []

      // if antenna is reading stop then
      if (this.isReading()) {
        if (AppStore.getWithTurnSequentialAntennas()) await this.turn_sequential('stop')
        else await this.turn('stop')
      }

      // check if workstation was already chosen
      if (AppStore.defaultWorkstation) {
        this.workstation = AppStore.defaultWorkstation
        return
      }

      if (!AppStore.workstations || AppStore.workstations.length === 0) throw new Error('No workstations found!')

      // set default workstation or select the first one
      const defaultCode = AppStore.loggedUser?.place?.attributes?.default_workstation_code ?? false
      this.workstation = AppStore.workstations?.find((ws) => ws.code === defaultCode)
    } catch (error) {
      if (!AppStore.getEmulation()) throw error
    }
  }

  setOnDecodedItemCallback(
    onDecodedItemCallback: (item: { [epc: string]: DecodedItem }, tag: TmrTag[]) => void,
    decodeRequest?: DecodeRequest
  ) {
    this.decodeRequest = decodeRequest
    this.onDecodedItemCallback = onDecodedItemCallback
  }

  setOnTagReadCallback(onTagReadCallback: (tag: TmrTag) => void) {
    this.onTagReadCallback = onTagReadCallback
  }

  clear() {
    this.tags = []
  }

  initUhfTags(identifiers: string[]) {
    const tags: TmrTag[] = []
    identifiers.map((idt) => {
      tags.push({
        type: 'UHF_TAG',
        epc: idt,
        uid: undefined,
        antennaId: 0,
        timestamp: 0,
        antennaIdDb: '',
        channelIndex: 0,
        firstSeen: 0,
        inventoryParameterSpecID: 0,
        lastSeen: 0,
        peakRSSI: 0,
        roSpecID: 0,
        tagSeenCount: 0,
        code: '',
        pending: false,
        processing: false
      })
    })
    this.tags = tags
  }

  removeTags(identifiers: string[]) {
    this.tags = this.tags.filter((tag) =>
      tag.epc ? !identifiers.includes(tag.epc) : tag.uid ? !identifiers.includes(tag.uid) : true
    )
  }

  setDecodeFunction(decodeFunction: (epcs: string[]) => Promise<any>) {
    this.decodeFunction = decodeFunction
  }

  isReading = (): boolean => {
    if (this.webSocket && this.webSocket.length > 0 && this.webSocket[0])
      return this.webSocket[0].readyState === WebSocket.OPEN
    else return false
  }

  async emulateTag(rfidTag: Partial<TmrTag>) {
    this.onTagRead(JSON.stringify(rfidTag))
  }

  async emulateTags(tags: string[]) {
    this.emulateTagAuto(tags.map((t) => ({ epc: t })))
  }

  async emulateTagAuto(rfidTags: Partial<TmrTag> | Partial<TmrTag>[]) {
    this.onStartCallback && (await this.onStartCallback())
    this.onStartCallbackAntennaButton && (await this.onStartCallbackAntennaButton())
    if (Array.isArray(rfidTags)) {
      rfidTags.map((rfidTag) => this.onTagRead(JSON.stringify(rfidTag)))
    } else {
      this.onTagRead(JSON.stringify(rfidTags))
    }

    setTimeout(async () => {
      this.onStopCallback && (await this.onStopCallback())
      this.onStopCallbackAntennaButton && (await this.onStopCallbackAntennaButton())
    }, 1000)
  }

  itemFromTag(tag: TmrTag): { identifiers: Partial<ItemIdentifier>[] } {
    return { identifiers: [{ id: tag.epc, code: tag.epc, type: 'TAG' }] }
  }

  decodeTags = debounce(
    () => {
      const tagsToDecode = this.tags.filter((t) => t.pending && !t.processing)
      if (tagsToDecode.length == 0) return
      tagsToDecode.forEach((t) => (t.processing = true))
      const { decodeFunction } = this
      if (decodeFunction) {
        decodeFunction(tagsToDecode.map((tag) => tag.epc ?? tag.uid))
          .then((result) => {
            this.onDecodedItemCallback?.(result, tagsToDecode)
          })
          .catch((e) => {
            /** @todo Estrarre quali tag sono andati in errore ed eliminarli */
            // this.tags = this.tags.filter((t) => t.epc !== tag.epc)
            console.error(e)
          })
          .finally(() => {
            this.onPendingTagsChangeAntennaButton?.(0)
            tagsToDecode.forEach((tag) => {
              tag.pending = false
              tag.processing = false
            })
          })
        return
      }
      if (!(this.decodeRequest && this.decodeRequest?.url)) throw new Error('decodeUrl not set')

      const fields = this.decodeRequest.epcFields || 'identifierCodes'
      const paramDecode = {
        ...this.decodeRequest.payload
      }
      paramDecode[fields] = tagsToDecode.map((tag) => tag.epc ?? tag.uid)
      Items.decode(this.decodeRequest.url, paramDecode)
        .then((items) => {
          this.onDecodedItemCallback?.(items, tagsToDecode)
        })
        .catch((err) => {
          showToastError(err)
        })
        .finally(() => {
          tagsToDecode.forEach((tag) => {
            tag.pending = false
            tag.processing = false
          })
          this.onPendingTagsChangeAntennaButton?.(this.tags.filter((t) => t.pending).length)
        })
    },
    this.debounceDecodeTime || 300,
    { maxWait: 2000 }
  )

  onTagRead(stringTagData: string) {
    this.startTimer()
    let tag: TmrTag
    try {
      tag = JSON.parse(stringTagData)
      const tagExists = this.tags.find((t) =>
        tag.uid && tag.uid !== '' ? tag.uid === t.uid : tag.epc && tag.epc !== '' ? tag.epc === t.epc : false
      )
      if (tagExists) {
        return
      }

      if (!tag.uid && !tag.epc) {
        return
      }

      if (this.ignoreReadingTagsWithPrefixes.length > 0 && tag.epc && tag.epc !== null) {
        //si controlla che tag non inizi con uno dei prefissi impostati
        let validStartEpc = true
        this.ignoreReadingTagsWithPrefixes.map((str) => {
          if (tag.epc.startsWith(str)) {
            validStartEpc = false
          }
        })

        if (!validStartEpc) {
          return
        }
      }

      if (!!this.onTagReadCallback) {
        this.tags.push({ ...tag, pending: false, processing: false })
        this.onTagReadCallback(tag)
      } else {
        if (this.batchInterval) {
          this.tags.push({ ...tag, pending: false, processing: false })
          this.manageBatchTags(tag)
          this.startBatchTimer(false)
        } else {
          this.tags.push({ ...tag, pending: true, processing: false })
          this.onPendingTagsChangeAntennaButton?.(this.tags.filter((t) => t.pending).length)
          this.decodeTags()
        }
      }
    } catch (err) {
      console.error(err)
      return
    }
  }

  manageBatchTags = (tag) => {
    const index = this.batchIntervalTags.findIndex((t) => !t.processing && t.tags.length < this.batchIntervalTagCount)
    if (index === -1) {
      this.batchIntervalTags.push({
        processing: false,
        completed: false,
        tags: [{ ...tag, pending: true }]
      })
    } else {
      this.batchIntervalTags[index].tags.push({ ...tag, pending: true })
    }
  }

  startBatchTimer = (destroy) => {
    if (destroy) {
      this.stopBatchTimer()
    }
    if (!this.batchIntervalTimer) {
      const index = this.batchIntervalTags.findIndex((t) => !t.completed && !t.processing)
      if (index !== -1) {
        this.batchIntervalTimer = setTimeout(() => {
          this.processedBatch()
        }, this.batchIntervalTime)
      }
    }
  }

  stopBatchTimer = () => {
    if (!this.batchIntervalTimer) return
    clearTimeout(this.batchIntervalTimer)
    this.batchIntervalTimer = undefined
  }

  processedBatch = () => {
    const index = this.batchIntervalTags.findIndex((t) => !t.completed && !t.processing)
    if (index !== -1) {
      this.batchIntervalTags[index].processing = true
      const tagsToDecode = this.batchIntervalTags[index].tags.filter((t) => t.pending && !t.processing)
      if (tagsToDecode.length == 0) {
        this.batchIntervalTags[index].completed = true
        this.startBatchTimer(true)
        return
      }
      tagsToDecode.forEach((t) => (t.processing = true))
      const { decodeFunction } = this
      if (decodeFunction) {
        decodeFunction(tagsToDecode.map((tag) => tag.epc ?? tag.uid))
          .then((result) => {
            this.onDecodedItemCallback?.(result, tagsToDecode)
          })
          .catch((e) => {
            this.batchIntervalTags[index].processing = false
            /** @todo Estrarre quali tag sono andati in errore ed eliminarli */
            // this.tags = this.tags.filter((t) => t.epc !== tag.epc)
            console.error(e)
          })
          .finally(() => {
            this.batchIntervalTags[index].completed = true
            this.onPendingTagsChangeAntennaButton?.(0)
            tagsToDecode.forEach((tag) => {
              tag.pending = false
            })
            this.startBatchTimer(true)
          })
        return
      }
      if (!(this.decodeRequest && this.decodeRequest?.url)) throw new Error('decodeUrl not set')

      const fields = this.decodeRequest.epcFields || 'identifierCodes'
      const paramDecode = {
        ...this.decodeRequest.payload
      }
      paramDecode[fields] = tagsToDecode.map((tag) => tag.epc ?? tag.uid)
      Items.decode(this.decodeRequest.url, paramDecode)
        .then((items) => {
          this.onDecodedItemCallback?.(items, tagsToDecode)
        })
        .catch((err) => {
          this.batchIntervalTags[index].processing = false
          showToastError(err)
        })
        .finally(() => {
          this.batchIntervalTags[index].completed = true
          tagsToDecode.forEach((tag) => {
            tag.pending = false
          })
          this.onPendingTagsChangeAntennaButton?.(this.getBatchPendingTagCount())
          this.startBatchTimer(true)
        })
    }
  }

  getBatchPendingTagCount = () => {
    let count = 0
    for (let l = 0; l < this.batchIntervalTags.length; l++) {
      count += this.batchIntervalTags[l].tags.filter((t) => t.pending).length
    }
    return count
  }

  async fillRfidAntennas(workstation: TmrWorkstation) {
    if (!workstation.antennas) {
      const rfidAntennas = await Promise.all(
        workstation.rfidAntennaIds.map(async (antennaId) => {
          return RfidAntennas.get<TmrRfidAntenna>(antennaId)
        })
      )
      workstation.antennas = rfidAntennas
    }
  }

  async fillBalance(workstation: TmrWorkstation) {
    if (!workstation.balance && !!workstation.balanceId) {
      try {
        const balance = await Balances.get<TmrBalance>(workstation.balanceId)
        workstation.balance = balance
      } catch (error) {
        console.error('Cannot find balance')
      }
    }
  }

  async turn(type: 'start' | 'stop' = 'start'): Promise<string[] | undefined> {
    if (!this.workstation) return undefined
    await this.fillRfidAntennas(this.workstation)
    this.deviceManagerApi = create({
      baseURL: `${this.workstation.deviceManagerUrl}`,
      timeout: 3000
    })
    const wsConfigurations: string[] = []
    try {
      // eslint-disable-next-line consistent-return
      for (let index = 0; index < this.workstation.antennas.length; index++) {
        const antenna = this.workstation.antennas[index]
        await sleep(100)

        const payloadStartAntenna: any = {
          ...antenna.reader,
          id: antenna.reader.code,
          antennas: this.workstation.antennas
        }
        let param = `sessionId=${this.sessionId}`
        if (type === 'start') param += '&memoryBank=TID&address=0&numBlocks=6'
        const response = await this.deviceManagerApi.post(
          `/api/v1/readers/antenna/${antenna.id}/${type}?${param}`,
          payloadStartAntenna
        )

        if (response.ok) {
          if (type === 'start') {
            this.onStartCallback && (await this.onStartCallback())
            this.onStartCallbackAntennaButton && (await this.onStartCallbackAntennaButton())
            wsConfigurations.push(
              `${this.workstation?.deviceManagerWebsocket}/rfidAntennas/scannings?antennaId=${antenna.id}`
            )
          } else wsConfigurations.push('stopped')
        } else if (type === 'start') {
          if (!AppStore.getEmulation()) {
            Sounds.error()
          }
          if (this.connectionFailedCallback) {
            this.connectionFailedCallback()
            return []
          }
          if (!AppStore.getEmulation()) {
            throw new Error()
          }
        }
      }
    } catch (e) {
      if (!AppStore.getEmulation() && type === 'start') {
        showToast({
          title: __(T.error.error),
          description: __(T.error.workstation_could_not_be_started, { code: this.workstation.code }),
          status: 'error'
        })
      }
      return []
    }
    if (type === 'start' && wsConfigurations.length > 0) {
      Sounds.scan()
    }
    return wsConfigurations
  }

  async closeAnyWebSocket() {
    if (this.webSocket && this.webSocket.length > 0) {
      let ws: any
      for (let w = 0; w < this.webSocket.length; w++) {
        if (this.webSocket[w]) {
          ws = this.webSocket[w]
          if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING) {
            await ws.close()
          }
        }
      }
    }
  }

  async turn_process_antenna(type, onStop?) {
    if (this.workstation) {
      try {
        const antenna = this.workstation.antennas[this.current_antenna]
        let executeCall = true
        let wsAntenna: any
        /*
         * check antenna's readyState based on the type to avoid making unnecessary calls
         */
        if (this.webSocket) {
          wsAntenna = this.webSocket[this.current_antenna]
          if (type === 'stop' && !wsAntenna) {
            executeCall = false
            if (this.ws_configurations[this.current_antenna]) {
              this.ws_configurations[this.current_antenna] = 'stopped'
            } else {
              this.ws_configurations.push('stopped')
            }
          } else if (
            type === 'start' &&
            wsAntenna &&
            (wsAntenna.readyState === WebSocket.OPEN || wsAntenna.readyState === WebSocket.CONNECTING)
          ) {
            executeCall = false
          }
        }
        if (executeCall) {
          const payloadStartAntenna: any = {
            ...antenna.reader,
            id: antenna.reader.code,
            antennas: this.workstation.antennas
          }
          let param = `sessionId=${this.sessionId}`
          if (type === 'start') param += '&memoryBank=TID&address=0&numBlocks=6'
          const response = await this.deviceManagerApi.post(
            `/api/v1/readers/antenna/${antenna.id}/${type}?${param}`,
            payloadStartAntenna
          )
          if (response.ok) {
            if (type === 'start') {
              if (this.ws_configurations[this.current_antenna]) {
                this.ws_configurations[this.current_antenna] =
                  `${this.workstation?.deviceManagerWebsocket}/rfidAntennas/scannings?antennaId=${antenna.id}`
              } else {
                this.ws_configurations.push(
                  `${this.workstation?.deviceManagerWebsocket}/rfidAntennas/scannings?antennaId=${antenna.id}`
                )
              }
              const webSocket = new WebSocket(this.ws_configurations[this.current_antenna])
              if (webSocket) {
                webSocket.onmessage = (ev) => this.onTagRead(ev.data)
                webSocket.onerror = (ev) => {
                  AppStore.sendDataDogLog('WebSocket Antenna Error', {})
                  onStop ? onStop() : this.stopWebSocket() // add param true for automatic restart
                }
                webSocket.onclose = (ev) => {
                  AppStore.sendDataDogLog('WebSocket Antenna Close', { message: ev.reason, code: ev.code })
                  onStop ? onStop() : this.isManualStop ? () => {} : this.stopWebSocket()
                }
                this.webSocket?.push(webSocket)
                return true
              } else {
                throw new Error()
              }
            } else {
              if (this.ws_configurations[this.current_antenna]) {
                this.ws_configurations[this.current_antenna] = 'stopped'
              } else {
                this.ws_configurations.push('stopped')
              }
              //si chiude la webSocket relativa
              if (
                wsAntenna &&
                (wsAntenna.readyState === WebSocket.OPEN || wsAntenna.readyState === WebSocket.CONNECTING)
              ) {
                await wsAntenna.close()
              }
              return true
            }
          } else {
            throw new Error()
          }
        } else {
          return true
        }
      } catch (error) {
        this.startErrorAntenna(type)
        return false
      }
    } else {
      this.startErrorAntenna(type)
      return false
    }
  }

  async startErrorAntenna(type) {
    if (type === 'start') {
      this.ws_configurations = []
    }
  }

  async turn_process(type, onStop?) {
    this.current_antenna++
    if (this.workstation && this.current_antenna < this.workstation.antennas.length) {
      const res = await this.turn_process_antenna(type, onStop)
      if (res && this.ws_configurations.length > 0) {
        return this.turn_process(type, onStop)
      }
    }
    return this.ws_configurations
  }

  async turn_sequential(type: 'start' | 'stop' = 'start', onStop?) {
    this.current_antenna = -1
    if (type === 'start') {
      this.webSocket = []
      this.ws_configurations = []
    }
    if (!this.workstation) return undefined
    this.deviceManagerApi = create({
      baseURL: `${this.workstation.deviceManagerUrl}`,
      timeout: 5000
    })
    await this.fillRfidAntennas(this.workstation)
    await this.turn_process(type, onStop)
    //console.warn(this.ws_configurations)
    if (type === 'start') {
      if (this.ws_configurations.length === 0) {
        if (!this.restartAntennas) {
          if (!AppStore.getEmulation()) {
            showToast({
              title: __(T.error.error),
              description: __(T.error.workstation_could_not_be_started, { code: this.workstation.code }),
              status: 'error'
            })
            Sounds.error()
          }
          await this.stop(true)
        }
      } else {
        Sounds.scan()
      }
    }
    return this.ws_configurations
  }

  async start(
    onTagReadCallback?: (tag: TmrTag) => void,
    onStop?: () => void,
    connectionFailedCallback?: () => void,
    restart = false
  ): Promise<boolean> {
    if (this.restart_onerror_counter === this.restart_onerror_max - 1) {
      this.restart_onerror_counter = 0
    }
    const with_turn_sequential = AppStore.getWithTurnSequentialAntennas()
    this.restartAntennas = restart
    this.connectionFailedCallback = connectionFailedCallback
    if (onTagReadCallback) this.onTagReadCallback = onTagReadCallback

    let wsConfigurations
    if (with_turn_sequential) wsConfigurations = await this.turn_sequential('start', onStop)
    else wsConfigurations = await this.turn('start')
    if (!wsConfigurations || wsConfigurations.length === 0) {
      // check if restart antennas
      if (this.restartAntennas) {
        await this.restartAutomaticAntennas()
        return false
      }

      if (this.connectionFailedCallback && with_turn_sequential) {
        this.connectionFailedCallback()
      }

      if (!with_turn_sequential) {
        this.onStopCallback && (await this.onStopCallback())
        this.onStopCallbackAntennaButton && (await this.onStopCallbackAntennaButton())
      }
      return false
    }

    if (with_turn_sequential) {
      this.onStartCallback && (await this.onStartCallback())
      this.onStartCallbackAntennaButton && (await this.onStartCallbackAntennaButton())
    } else {
      this.webSocket = []
      for (let i = 0; i < wsConfigurations.length; i += 1) {
        const webSocket = new WebSocket(wsConfigurations[i])

        if (webSocket) {
          webSocket.onmessage = (ev) => this.onTagRead(ev.data)
          webSocket.onerror = (ev) => {
            AppStore.sendDataDogLog('WebSocket Antenna Error', {})
            onStop ? onStop() : this.stopWebSocket()
          }
          webSocket.onclose = (ev) => {
            AppStore.sendDataDogLog('WebSocket Antenna Close', { message: ev.reason, code: ev.code })
            onStop ? onStop() : this.isManualStop ? () => {} : this.stopWebSocket()
          }

          this.webSocket?.push(webSocket)
        } else {
          return false
        }
      }
    }
    this.restartAntennas = false
    this.restart_onerror_counter = 0
    this.wakeUpSocket()
    this.startTimer()
    return true
  }

  restartAutomaticAntennas = async () => {
    setTimeout(async () => {
      this.restart_onerror_counter++
      if (this.restart_onerror_counter < this.restart_onerror_max) {
        await this.start(
          this.onTagReadCallback,
          undefined,
          this.connectionFailedCallback,
          this.restart_onerror_counter < this.restart_onerror_max - 1
        )
      } else {
        // show toast for antenna not started
      }
    }, 1000)
  }

  stopWebSocket = async (restart = false) => {
    await this.stop(false)
    if (restart) {
      this.restartAntennas = restart
      await this.restartAutomaticAntennas()
    }
  }

  stop = async (manual = true): Promise<boolean> => {
    this.isManualStop = manual

    let stopped
    if (AppStore.getWithTurnSequentialAntennas()) {
      stopped = await this.turn_sequential('stop')
      this.ws_configurations = []
    } else {
      stopped = await this.turn('stop')
      if (this.webSocket) {
        this.webSocket.forEach((ws) => ws && ws.readyState === WebSocket.OPEN && ws.close())
      }
    }

    this.webSocket = undefined
    this.onStopCallback && (await this.onStopCallback())
    this.onStopCallbackAntennaButton && (await this.onStopCallbackAntennaButton())
    this.resetWakeupTimeout()
    this.stopTimer()
    if (manual) {
      setTimeout(() => {
        this.isManualStop = false
      }, 300)
    }

    if (
      stopped &&
      this.workstation &&
      this.workstation.antennas?.length === stopped.filter((elem) => elem === 'stopped').length
    ) {
      return true
    }

    return false
  }

  resetWakeupTimeout = () => {
    if (this.wakeUpSocketTimeout) {
      clearInterval(this.wakeUpSocketTimeout)
      this.wakeUpSocketTimeout = undefined
    }
  }

  wakeUpSocket = () => {
    this.resetWakeupTimeout()
    this.wakeUpSocketTimeout = setTimeout(() => {
      if (this.isReading()) {
        this.webSocket?.forEach((ws) => ws && ws.send('{}'))
        this.wakeUpSocket()
      } else {
        this.webSocket?.forEach((ws) => ws && ws.readyState === WebSocket.OPEN && ws.close())
        this.webSocket = undefined
      }
    }, 3000) as any
  }

  getAntenna = () => {
    let antennaUHF
    if (this.workstation) {
      const antennas = this.workstation?.antennas ?? []
      antennaUHF = antennas.find(
        (a) => a.reader.settings.supportedTagType === 'UHF' && a.reader.settings.writeEnabled === 'true'
      )
      if (!antennaUHF) {
        antennaUHF = antennas.find((a) => WRITE_DEVICE_TYPE_READER.includes(a.reader.deviceType))
      }
    }
    return antennaUHF
  }

  writeEpc = async (target: string, data: string, password?: string, useTidToWrite?: boolean) => {
    const type = 'write'

    const antennaUHF = this.getAntenna()
    if (!antennaUHF) throw new Error('antennaUHF not set')

    const { reader } = antennaUHF
    this.deviceManagerApi = create({
      baseURL: `${this.workstation?.deviceManagerUrl}`,
      timeout: 3000
    })
    const numBlocks = Math.ceil(data.length / 4)
    const path = '/api/v1/readers/antenna/'
    let queryParam = `sessionId=${this.sessionId}&memoryBank=EPC&address=2&targetEpc=${target}&data=${data}&numBlocks=${numBlocks}`
    if (password && password !== '') {
      queryParam += '&password=' + password
    }
    if (useTidToWrite) {
      queryParam += '&targetType=TID' //&beepOnError=true&beepOnSuccess=true
    }

    const antennas = this.workstation?.antennas ?? []
    const antennasToWrite: TmrRfidAntenna[] = []
    antennas.map((at) => {
      antennasToWrite.push({
        id: at.id,
        creationDate: at.creationDate,
        lastModifyDate: at.lastModifyDate,
        code: at.code,
        description: at.description,
        zoneId: at.zoneId,
        reader: at.reader,
        readerPort: at.readerPort,
        txPower: at.writeTxPower ?? at.txPower,
        rxSensitivity: at.rxSensitivity
      })
    })

    const payloadWriteTag: any = {
      ...reader,
      id: reader.code,
      antennas: antennasToWrite
    }
    try {
      const res = await this.deviceManagerApi.post<{ value: string }>(
        `${path}${antennaUHF.id}/${type}?${queryParam}`,
        payloadWriteTag
      )
      if (AppStore.getEmulation() && !res.ok) {
        console.log('RfidReader.writeEpc(): write error during emulation', res)
        return { ok: true }
      }
      return res
    } catch (e) {
      /*if (AppStore.getEmulation()) {
        return { ok: true }
      }*/
      throw e
    }
  }

  killTag = async (target: string, password?: string) => {
    const antennaUHF = this.getAntenna()
    if (!antennaUHF) throw new Error('antennaUHF not set')

    const { reader } = antennaUHF
    this.deviceManagerApi = create({
      baseURL: `${this.workstation?.deviceManagerUrl}`,
      timeout: 3000
    })

    const path = '/api/v1/readers/antenna/'
    let queryParam = `sessionId=${this.sessionId}&targetEpc=${target}`
    if (password && password !== '') {
      queryParam += '&password=' + password
    }

    const antennas = this.workstation?.antennas ?? []
    const antennasToWrite: TmrRfidAntenna[] = []
    antennas.map((at) => {
      antennasToWrite.push({
        id: at.id,
        creationDate: at.creationDate,
        lastModifyDate: at.lastModifyDate,
        code: at.code,
        description: at.description,
        zoneId: at.zoneId,
        reader: at.reader,
        readerPort: at.readerPort,
        txPower: at.writeTxPower ?? at.txPower,
        rxSensitivity: at.rxSensitivity
      })
    })

    const payloadWriteTag: any = {
      ...reader,
      id: reader.code,
      antennas: antennasToWrite
    }
    try {
      const res = await this.deviceManagerApi.post<{ value: string }>(
        `${path}${antennaUHF.id}/kill?${queryParam}`,
        payloadWriteTag
      )
      if (AppStore.getEmulation() && !res.ok) {
        console.log('RfidReader.killTag(): kill error during emulation', res)
        return { ok: true }
      }
      return res
    } catch (e) {
      throw e
    }
  }

  getWeight = async () => {
    if (!this.workstation) throw new Error('Workstation not set')
    await this.fillBalance(this.workstation)
    this.deviceManagerApi = create({
      baseURL: `${this.workstation.deviceManagerUrl}`,
      timeout: 3000
    })

    if (!this.workstation?.balance) throw new Error('Workstation balance not set')

    return this.deviceManagerApi.post<{ weight: number; response: string; error?: string }>(`/api/v1/balances/weight`, {
      ip: this.workstation.balance!.settings.ipAddress,
      port: this.workstation.balance!.settings.port
    })
  }

  startWsCustomCommands = async (operationType: string) => {
    if (!this.workstation) throw new Error('Workstation not set')
    const antenna = this.workstation.antennas.find((a) =>
      READERS_SUPPORTING_CUSTOM_COMMANDS.includes(a.reader.deviceType)
    )
    if (!antenna) throw new Error('Only possible on readers: ' + READERS_SUPPORTING_CUSTOM_COMMANDS.join(', '))
    const payloadStartAntenna: any = {
      ...antenna.reader,
      id: antenna.reader.code,
      antennas: this.workstation.antennas
    }
    await this.deviceManagerApi.post(
      `/api/v1/readers/antenna/${antenna.id}/start-custom-operation?operationType=${operationType}&sessionId=${this.sessionId}`,
      payloadStartAntenna
    )
    const wsUrl = `${this.workstation?.deviceManagerWebsocket}/rfidAntennas/commands?antennaId=${antenna.id}`
    await new Promise((resolve, reject) => {
      this.customCommandsWs = new WebSocket(wsUrl)
      this.customCommandsWs.onopen = () => {
        if (!this.customCommandsWs) reject(false)
        this.customCommandsWs!.onerror = (ev) => {
          console.error(ev)
          this.customCommandsWs?.close()
          this.customCommandsWs = undefined
        }
        resolve(true)
      }
    })
  }

  stopWsCustomCommands = async () => {
    if (!this.workstation) throw new Error('Workstation not set')
    await this.fillRfidAntennas(this.workstation)
    if (!this.workstation.antennas || this.workstation.antennas.length === 0) {
      console.log('no antennas')
      throw new Error('No antennas found for this workstation')
    }
    const antenna = this.workstation?.antennas.find((a) => a.reader.deviceType === 'mediol-40')
    if (!antenna) {
      console.log('no Medio antennas')
      console.warn('Custom commands are only possible on Medio L-40 reader')
    } else {
      // if (this.deviceManagerApi) {
      //   const payloadStartAntenna: any = {
      //     ...antenna.reader,
      //     id: antenna.reader.code,
      //     antennas: this.workstation.antennas,
      //   }
      //   await this.deviceManagerApi.post(
      //     `/api/v1/readers/antenna/${antenna.id}/stop-custom-operation?sessionId=${this.sessionId}`,
      //     payloadStartAntenna
      //   )
      // }
      if (this.customCommandsWs) {
        this.customCommandsWs.close()
        this.customCommandsWs = undefined
      }
    }
  }

  sendWsCustomCommandForResponse: (command: any, waitForSuccess?: boolean) => Promise<any> = (
    command,
    waitForSuccess = true
  ) => {
    return new Promise((resolve, reject) => {
      if (!this.customCommandsWs || this.customCommandsWs.readyState !== WebSocket.OPEN) {
        reject(false)
      }
      this.customCommandsWs!.onmessage = (ev) => {
        if (!waitForSuccess) resolve(ev.data)
        else {
          const data = JSON.parse(ev.data)
          if (data.success) {
            resolve(data)
          }
        }
      }
      this.customCommandsWs!.send(JSON.stringify(command))
      this.customCommandsWs!.onerror = (ev) => {
        reject(ev)
      }
    })
  }

  async getDeviceManagerStatus() {
    if (!this.workstation) throw new Error('Workstation not set')
    if (!this.workstation.attributes?.deviceManagerUpdateUrl) throw new Error('DeviceManagerUpdateUrl not set')

    this.deviceManagerApi = create({
      baseURL: `${this.workstation.attributes?.deviceManagerUpdateUrl}`,
      timeout: 3000
    })
    return this.deviceManagerApi.get<DeviceManagerStatus>('/api/v1/status')
  }

  async checkLastVersionAvailable(config: UpdateConfig) {
    if (!this.workstation) throw new Error('Workstation not set')
    if (!this.workstation.attributes?.deviceManagerUpdateUrl) throw new Error('DeviceManagerUpdateUrl not set')

    this.deviceManagerApi = create({
      baseURL: `${this.workstation.attributes?.deviceManagerUpdateUrl}`,
      timeout: 3000,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    try {
      const response = await this.deviceManagerApi.post<{ version: string }>('/api/v1/last-version-available', config)

      return response
    } catch (error) {
      // Log dell'errore per debug
      console.error('Error details:', error)
      throw error
    }
  }

  async updateDeviceManager(config: UpdateConfig) {
    if (!this.workstation) throw new Error('Workstation not set')
    if (!this.workstation.attributes?.deviceManagerUpdateUrl) throw new Error('DeviceManagerUpdateUrl not set')
    this.deviceManagerApi = create({
      baseURL: `${this.workstation.attributes?.deviceManagerUpdateUrl}`,
      timeout: 3000
    })
    return this.deviceManagerApi.post('/api/v1/update', config)
  }

  async restartDeviceManager() {
    if (!this.workstation) throw new Error('Workstation not set')
    if (!this.workstation.attributes?.deviceManagerUpdateUrl) throw new Error('DeviceManagerUpdateUrl not set')

    this.deviceManagerApi = create({
      baseURL: `${this.workstation.attributes?.deviceManagerUpdateUrl}`,
      timeout: 3000
    })
    return this.deviceManagerApi.post('/api/v1/restart')
  }

  private extractVersion(status: string): string {
    const match = status.match(/RUNNING\s+(\d+\.\d+\.\d+)/)
    return match ? match[1] : ''
  }

  async checkForUpdates(
    config: UpdateConfig
  ): Promise<{ hasUpdate: boolean; currentVersion: string; latestVersion: string } | null> {
    try {
      // Se non c'è una workstation, proviamo a inizializzare
      if (!this.workstation) {
        await this.initialize()
        if (!this.workstation) return null
      }

      // Se siamo in modalità emulazione, usiamo dei dati mock
      if (AppStore.getEmulation()) {
        const mockCurrentVersion: string = '1.5.0'
        const mockLatestVersion: string = '1.5.0'
        return {
          hasUpdate: mockCurrentVersion !== mockLatestVersion,
          currentVersion: mockCurrentVersion,
          latestVersion: mockLatestVersion
        }
      }

      const statusResponse = await this.getDeviceManagerStatus()
      if (!statusResponse.ok || !statusResponse.data) {
        return null
      }

      const currentVersion = this.extractVersion(statusResponse.data.status)
      if (!currentVersion) {
        return null
      }

      const latestVersionResponse = await this.checkLastVersionAvailable(config)
      if (!latestVersionResponse.ok || !latestVersionResponse.data) {
        return null
      }

      // Estraiamo la versione dall'oggetto response
      const latestVersion = latestVersionResponse.data.version

      return {
        hasUpdate: currentVersion !== latestVersion,
        currentVersion,
        latestVersion
      }
    } catch (error) {
      console.log('Errore durante il controllo degli aggiornamenti:', error)
      return null
    }
  }

  async pollUpdateStatus(): Promise<{ success: boolean; error?: string }> {
    if (!this.workstation) throw new Error('Workstation not set')

    if (AppStore.getEmulation()) {
      await new Promise((resolve) => setTimeout(resolve, 5000))
      return { success: true }
    }

    const pollInterval = 2000 // 2 secondi
    const initialVersion = this.currentVersion

    await new Promise((resolve) => setTimeout(resolve, 20000))

    while (true) {
      try {
        const statusResponse = await this.getDeviceManagerStatus()

        // Se torna RUNNING, controlliamo la versione
        if (statusResponse.data.status.includes('RUNNING')) {
          const currentVersion = this.extractVersion(statusResponse.data.status)

          // Se è tornato RUNNING ma con la vecchia versione, è fallito
          if (currentVersion === initialVersion) {
            return {
              success: false,
              error: "L'aggiornamento è fallito."
            }
          }

          // Se è RUNNING con una nuova versione, successo!
          if (currentVersion !== initialVersion) {
            return { success: true }
          }
        }

        await new Promise((resolve) => setTimeout(resolve, pollInterval))
      } catch (error) {
        console.log('Polling error:', error)
        await new Promise((resolve) => setTimeout(resolve, pollInterval))
      }
    }
  }

  async performUpdate(config: UpdateConfig): Promise<{ success: boolean; error?: string }> {
    if (!this.workstation) throw new Error('Workstation not set')

    // Se siamo in modalità emulazione, controlliamo shouldFail
    if (AppStore.getEmulation()) {
      await new Promise((resolve) => setTimeout(resolve, 5000)) // Aspettiamo 5 secondi

      // Controlliamo se è stato impostato shouldFail nei test
      const shouldFail = (window as any).__TEST_SHOULD_FAIL__ || false

      if (shouldFail) {
        return {
          success: false,
          error: "Errore durante l'aggiornamento: impossibile completare l'operazione"
        }
      }

      return { success: true }
    }

    // Salva la versione corrente per il polling
    const statusResponse = await this.getDeviceManagerStatus()
    this.currentVersion = this.extractVersion(statusResponse.data.status)

    // Avvia l'aggiornamento
    await this.updateDeviceManager(config)

    // Polling dello stato dell'aggiornamento
    const updateResult = await this.pollUpdateStatus()

    if (updateResult.success) {
      return { success: true }
    }

    return updateResult
  }
}

export default new RfidReader()
